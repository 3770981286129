<template>
  <div class="container mt-4">
    <div class="card">
      <div class="card-header bg-primary text-white d-flex align-items-center">
        <div class="assistant-avatar mr-3"></div>
        <h5 class="mb-0">Assistent Chat</h5>
      </div>
      <div class="card-body">
        <form @submit.prevent="askQuestion">
          <div class="form-group">
            <label for="userQuestion">Stelle eine Frage:</label>
            <input
                type="text"
                id="userQuestion"
                class="form-control"
                v-model="question"
                placeholder="Geben eine Frage ein ..."
                required
            />
          </div>
          <button type="submit" class="btn btn-primary mt-2" :disabled="loading">
            <span v-if="loading">Nachdenken ...</span>
            <span v-else>Frage stellen</span>
          </button>
        </form>

        <div v-if="response" class="mt-4">
          <div class="d-flex align-items-center mb-3">
            <div class="assistant-avatar mr-3"></div>
            <h6 class="mb-0">Assistent:</h6>
          </div>

          <div class="border p-4 bg-light">
            <markdown-parser :source="response"></markdown-parser>
          </div>

          <div class="mt-3">
            <p>War diese Antwort hilfreich?</p>
            <button
                class="btn btn-primary btn-sm mr-2"
                :disabled="feedbackSent"
                @click="sendFeedback(true)"
            >
              Ja
            </button>
            <button
                class="btn btn-danger btn-sm"
                :disabled="feedbackSent"
                @click="sendFeedback(false)"
            >
              Nein
            </button>
          </div>
        </div>

        <div v-if="error" class="mt-4">
          <h6>Fehler:</h6>
          <p class="alert alert-danger">{{ error }}</p>
        </div>

        <small class="text-muted d-block mt-4">
          Hinweis: Die Antworten werden von einer künstlichen Intelligenz generiert und sind
          möglicherweise nicht zu 100 % korrekt. Bitte überprüfe die Informationen, falls erforderlich.
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownParser from "@/components/Common/MarkdownParser.vue";

export default {
  components: { MarkdownParser },
  data() {
    return {
      question: '', // Benutzerfrage
      response: null, // Antwort des Assistenten
      error: null, // Fehlermeldung
      loading: false, // Status für den Ladezustand
      feedbackSent: false, // Ob Feedback gesendet wurde
      inquiryId: null, // ID der aktuellen Anfrage
    };
  },
  methods: {
    async askQuestion() {
      // Reset response and error states
      this.response = null;
      this.error = null;
      this.feedbackSent = false;
      this.inquiryId = null;
      this.loading = true;

      try {
        // API-Aufruf an den Assistenten
        const res = await this.$api.post('support/assistant/ask-question', {
          question: this.question,
        });

        // Antwort speichern
        this.response = res.data.answer || 'Keine Antwort gefunden.';
        this.inquiryId = res.data.inquiry_id || null; // Anfrage-ID speichern
      } catch (err) {
        // Fehler abfangen und anzeigen
        this.error =
            err.response?.data?.message || 'Es ist ein Fehler aufgetreten.';
      } finally {
        this.loading = false;
      }
    },
    async sendFeedback(isHelpful) {
      if (!this.inquiryId) return;

      try {
        await this.$api.post('support/assistant/give-feedback', {
          inquiry_id: this.inquiryId,
          helpful: isHelpful,
        });

        // Feedback gesendet
        this.feedbackSent = true;
        this.error = null;
      } catch (err) {
        this.error =
            err.response?.data?.message || 'Fehler beim Senden des Feedbacks.';
      }
    },
  },
};
</script>

<style scoped>
/* Styling für den CSS-Avatar */
.assistant-avatar {
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 50%;
  border: 2px solid #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: #4b4b4b;
  text-transform: uppercase;
}

/* Optional: Initialen für den Avatar */
.assistant-avatar::before {
  content: "AI";
}
</style>
